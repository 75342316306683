import { createNamespacedHelpers } from "vuex";

const storesKeyedByModuleName = {};

export default (moduleName, vuexModule) => {
    const stores = storesKeyedByModuleName[moduleName] = (storesKeyedByModuleName[moduleName] || []);

    const registerModuleToStore = (store) => {
        const shouldRegisterModule = stores.indexOf(store) === -1;

        stores.push(store);

        shouldRegisterModule && store.registerModule(moduleName, vuexModule);
    }

    const unregisterModuleFromStore = (store) => {
        const index = stores.indexOf(store);

        if (index === -1) return;

        stores.splice(index, 1);

        stores.indexOf(store) === -1 && store.unregisterModule(moduleName);
    }

    const RegisterVuexModuleMixin = {
        beforeCreate() {
            registerModuleToStore(this.$store);
        },
        beforeDestroy() {
            unregisterModuleFromStore(this.$store);
        }
    }

    return {
        registerModuleToStore,
        unregisterModuleFromStore,
        RegisterVuexModuleMixin,
        ...createNamespacedHelpers(moduleName)
    }
}